/* You can add global styles to this file, and also import other style files */

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

mat-card {
  overflow: hidden; }

.hidden {
  display: none !important; }

// currently tables are only used in card table cells
table {
  border: none;
  border-spacing: 0;

  tr {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0; }
    td {
      height: 24px;
      padding: 0 8px;

      div {
        // avoid .icon-fix
        display: flex;
        align-items: center; } } } }

// specially for badges append to action button
button.button-with-close-badget.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -5px; }

button.button-with-close-badget.mat-badge-medium.mat-badge-overlap.mat-badge-above {
  .mat-badge-content {
    top: -5px; } }

// reduce size of paginator (width: 28px smaller)
div.mat-paginator-range-label {
  margin: 0 16px 0 12px; }

// baseline of mac-icon is incorrect
// https://github.com/google/material-design-icons/issues/206
.icon-fix {
  transform: translateY(25%); }

// need space for longer translations (actions menu)
.mat-menu-panel {
  max-width: 90vw !important; }

// need space for longer names (domains menu)
.mat-select-panel {
  max-width: 90vw !important; }

.selection-counter {
  margin: 0 16px; }

.dialog {
  max-width: 80vw;
  max-height: 80vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .mat-dialog-container {
    padding: 24px;
    max-height: inherit;
    max-width: inherit;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }

  &.preview {
    .mat-dialog-container {
      padding: 0; } } }

.is-handset {
  .dialog {
    //height:    100vh
    width: 95vw;
    max-width: 95vw;
    max-height: 80vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &.preview {
      height: unset;
      width: unset;
      max-width: 95vw;
      max-height: 80vh; } } }
