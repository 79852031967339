@import "~@angular/material/theming";

@mixin card-selection($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  // all of these variables contain many additional variables

  &.is-handset {
    // handle all 3 types of card-table-rows
    // unfortunately host-selector doesnt work...
    app-card-table-row.selected::after,
    app-card-table-row-mediaitem.selected::after,
    app-card-table-row-ci-media.selected::after,
    app-card-table-row-ci-group.selected::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border: 4px solid mat-color($accent);
      box-sizing: border-box; } } }
