@import "~@angular/material/theming";

// custom component sass files for styling
@import "../../app/components/card-table/card-table-row/card-table-row.theme";

@mixin custom-components-theme($theme) {
  @include card-selection($theme); }

@mixin add-logo($logo-url) {
  @if ($logo-url) {
    .theme-logo {
      background-image: url($logo-url); } } }

@mixin create_theme($primary-palette, $accent-palette, $warn-palette, $logo-url: null) {
  $primary: mat-palette($primary-palette);
  $accent: mat-palette($accent-palette);
  $warn: mat-palette($warn-palette);

  $theme: mat-light-theme($primary, $accent, $warn);

  @include angular-material-theme($theme);
  @include custom-components-theme($theme);
  @include add-logo($logo-url); }
