@import "~@angular/material/theming";
@import "default/theme";
@import "intersportde/theme";
@import "plattformh2/theme";
@import "documenta/theme";
@import "mediamarkt/theme";
@import "saturn/theme";

@include mat-core();

body {
  @include default_theme();

  //
  // class names must be BCN NETWORK KEYS
  //

  &.intersportde {
    @include intersportde_theme(); }

  &.plattformh2 {
    @include plattformh2_theme(); }

  &.documenta {
    @include documenta_theme(); }

  &.mmat, &.mmbe, &.mmch, &.mmde, &.mmes, &.mshu, &.mmnlglobal, &.mmpl, &.mmpt, &.mmse, &.mmtr {
    @include mediamarkt_theme(); }

  &.satdegsm, &.sattest, &.saturnlu {
    @include saturn_theme(); } }
